* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}
a {
    color: inherit;
    text-decoration: none;
}

body {
    overflow-x: hidden;
    height: 100vh;
    min-height: 100vh;
    background: #f5f6f8;

    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

li {
    list-style: none;
}

#root {
    height: 100%;
    min-height: 100vh;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.scrollbar::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

.scrollbar::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F5F5F7;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #909EBB;
    border-radius: 10px;
}

.scrollbox {
    background:
            linear-gradient(white 30%, rgba(255, 255, 255, 0)),
            linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
            radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0)),
            radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 5px, 100% 5px;
    background-attachment: local, local, scroll, scroll;
}
